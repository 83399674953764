<template>

<!--Main Navigation-->
<header>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-tn-1">
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <button
        class="navbar-toggler"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>

      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Navbar brand -->
        <router-link class="navbar-brand mt-2 mt-sm-0" to="/">
          <img
            src="https://cdn.simplexitydynamics.com/icons/tyche/favicon-32x32.png"
            height="32"
            alt="Hutt Studio"
            loading="lazy"
          />
        </router-link>
      </div>
      <!-- Collapsible wrapper -->

      <!-- Right elements -->
      <div class="d-flex align-items-center">
        <!-- Icon -->
        <router-link class="nav-link" to="/"><i class="fas fa-home me-3"></i></router-link> &nbsp;&nbsp;&nbsp;
        <router-link class="nav-link" to="/works"><i class="fa-solid fa-gear fa-spin"></i></router-link>  &nbsp;&nbsp;&nbsp;
        
      </div>
      <!-- Right elements -->
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->


  
</header>






</template>

<script>
export default {
  name: 'NavTop',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}


.bg-tn-1 {
  background: linear-gradient(
            90deg,
            rgba(23, 33, 90, 0.795),
            rgba(1, 12, 77, 0.856) 100%
          );
}



.navbar .nav-link {
  color: #ffffff !important;
}


</style>
