<template>
  <div class="container">
   



      <div class="container-fluid py-5">
        <div class="bg-image" >
          <img src="https://cdn.simplexitydynamics.com/images/iot-1.jpg" class="w-100" />
          <div class="mask text-light d-flex justify-content-center flex-column text-center" style="background-color: rgba(0, 0, 0, 0.5)">
            <p class="m-0">Tyche Net use cases and works revolve around </p>
            <h4><i class="fa-solid fa-industry fa-beat-fade"></i> Industry 4.0 and 5.0. <i class="fa-regular fa-face-smile fa-bounce"></i></h4>
            <p class="m-0">&nbsp;</p>
            <p class="m-0">Our current initiatives are in these Industry 4.0 domains: Cybersecurity, System Integration, IoT, and Cloud.</p>
            <p class="m-0">As we build towards human centric design and sustainability, our solutions flow with Industry 5.0 and Society 5.0.</p>
          </div>
        </div>
      </div>

      <!-- Work items -->

      <div class="container-fluid py-5">

        <div class="row">
          <div class="col-lg-12">

            <div class="horizontal-timeline">

              <ul class="list-inline items">
                <li class="list-inline-item items-list">
                  <div class="px-4">
                    <div class="event-date badge bg-info"><i class="fa-solid fa-shield-virus fa-beat-fade fa-xl"></i></div>
                    <h5 class="pt-2">Cyber Security</h5>
                    <p class="text-muted">
                      Works are underway for SIEM and SOC using open source solutions.  Watch this item for updates.<br/>&nbsp;<br/>&nbsp;
                    </p>
                    <!-- <div>
                      <a href="#" class="btn btn-primary btn-sm">Read more</a>
                    </div> -->
                  </div>
                </li>
                <li class="list-inline-item items-list">
                  <div class="px-4">
                    <div class="event-date badge bg-success"><i class="fa-solid fa-link fa-fade fa-xl"></i></div>
                    <h5 class="pt-2">Systems Integration</h5>
                    <p class="text-muted">
                      Works are also underway. <i class="fa-solid fa-face-laugh-beam fa-bounce"></i> 30% to 40% of the solutions overlap with cybersecurity.  These are mostly distributed systems.
                      <br/>&nbsp;
                    </p>
                    <!-- <div>
                      <a href="#" class="btn btn-primary btn-sm">Read more</a>
                    </div> -->
                  </div>
                </li>
                <li class="list-inline-item items-list">
                  <div class="px-4">
                    <div class="event-date badge bg-danger"><i class="fa-solid fa-circle-nodes fa-flip fa-xl"></i></div>
                    <h5 class="pt-2">Internet of Things</h5>
                    <p class="text-muted">
                      These edge solutions, are the products we will be working on. <br/>&nbsp;<br/>&nbsp;<br/>&nbsp;
                    </p>
                    <!-- <div>
                      <a href="#" class="btn btn-primary btn-sm">Read more</a>
                    </div> -->
                  </div>
                </li>
                <li class="list-inline-item items-list">
                  <div class="px-4">
                    <div class="event-date badge bg-warning"><i class="fa-solid fa-cloud-bolt fa-fade fa-xl"></i></div>
                    <h5 class="pt-2">Cloud Computing</h5>
                    <p class="text-muted">
                      This is the infrastructure-as-code of Tyche Net.  Expect our write-ups to be available in <a href="https://codersinaction.com/" class="text-reset" target="_blank" rel="noreferrer noopener">Coders-In-Action</a>.
                      <br/>&nbsp;<br/>&nbsp;
                    </p>
                    <!-- <div>
                      <a href="#" class="btn btn-primary btn-sm">Read more</a>
                    </div> -->
                  </div>
                </li>
              </ul>

            </div>

          </div>
        </div>

        </div>

      <!-- /Work items -->

      <!-- Articles -->
      <div class="container-fluid py-5">

        <h4 class="mb-3">
            <a href="https://articles.codersinaction.com" target="_blank" rel="noreferrer noopener">Design Documents</a> : 
          </h4>
          
          <ul class="list-group list-group-light">
            <li class="list-group-item">
              <a href="https://articles.codersinaction.com/cybersecurity/iam.html" target="_blank" rel="noreferrer noopener">
                Identity and Access Management : Architecture Design 
              </a>
            </li>  
            <li class="list-group-item">
              <a href="https://articles.codersinaction.com/web/traditional.html" target="_blank" rel="noreferrer noopener">
                Traditional Web Architecture  
              </a>
            </li>
            <li class="list-group-item">
              <a href="https://articles.codersinaction.com/cybersecurity/siemsoar.html" target="_blank" rel="noreferrer noopener">
                SIEM, SOAR : Architecture Design
              </a>
            </li>
            <li class="list-group-item">
              <a href="https://articles.codersinaction.com/web/reactive.html" target="_blank" rel="noreferrer noopener">
                Reactive Web / System : Design and Architecture 
              </a>
            </li>
            <li class="list-group-item">
              <a href="https://articles.codersinaction.com/ops/cicd.html" target="_blank" rel="noreferrer noopener">
                CI / CD : Design and Architecture 
              </a>
            </li>
            <li class="list-group-item">
              <a href="https://articles.codersinaction.com/ops/maintenance.html" target="_blank" rel="noreferrer noopener">
                Backup and Maintenance : Design and Architecture 
              </a>
            </li>
          </ul>

      </div>


      <!-- / Articles -->



  </div>

</template>
  
<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}

.horizontal-timeline .items {
  border-top: 3px solid #e9ecef;
}

.horizontal-timeline .items .items-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}

.horizontal-timeline .items .items-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}

.horizontal-timeline .items .items-list .event-date {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  width: 75px;
  margin: 0 auto;
  font-size: 0.9rem;
  padding-top: 8px;
}

@media (min-width: 1140px) {
  .horizontal-timeline .items .items-list {
    display: inline-block;
    width: 24%;
    padding-top: 45px;
  }

  .horizontal-timeline .items .items-list .event-date {
    top: -40px;
  }
}
</style>