import { 
    createRouter, 
    createWebHashHistory 
} from "vue-router";

import HomePage from '../components/HomePage';
import WorksPage from '../components/WorksPage';


export default createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: '/',
        name: 'Home',
        component: HomePage,
    },{
        path: '/works',
        name: 'Works',
        component: WorksPage,
    }],
});