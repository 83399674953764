<template>
  <div class="container-fluid" style="background-color: black;">
    

        <!-- Background image -->
    <!-- <div id="intro" class="bg-image vh-100 shadow-1-strong"> -->
    <div >
      <div id="intro" class="ratio ratio-16x9">
        <video style="min-width: 100%; min-height: 100%;" playsinline autoplay muted loop>
          <source class="h-100" src="https://cdn.simplexitydynamics.com/vids/nodes3.mp4" type="video/mp4" />
        </video>
        <div class="mask-tn-1">
          <div class="container d-flex align-items-center justify-content-center text-center h-100">
            <div class="text-white">
              <h1 class="mb-3">Tyche Net</h1>
              <h5 class="mb-4">Systems of Simplexity Dynamics.</h5>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
    <!-- Background image -->

  <div class="container-fluid">
    <!-- Background image -->
    <div
        class="d-flex justify-content-center align-items-center"
        style="height: 100vh"
      >
        <div class="text-center">
          <img
            class="mb-4"
            src="https://cdn.simplexitydynamics.com/icons/tyche/android-chrome-512x512.png"
            style="width: 208px; height: 176px"
          />
          <h5 class="mb-3">{{ msg }}</h5>
          <p class="mb-3">---</p>
          <p class="mt-5 mb-3"></p>
            
          <h4 class="mb-3">
            Testing server capabilities: 
          </h4>
          
          <ul class="list-group list-group-light">
            <li class="list-group-item">Identity and Access Management [ Due mid August 2023 ]</li>
            <li class="list-group-item">Traditional and Reactive Web [ Due mid August 2023 ]</li>
            <li class="list-group-item">SIEM, SOAR </li>
            <li class="list-group-item">CI / CD [ Due mid August 2023 ]</li>
            <li class="list-group-item">Backup and Maintenance </li>
          </ul>

        </div>
      </div>
    <!-- Background image -->
    

  </div>

</template>
  
<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}
/* Height for devices larger than 576px */
@media (min-width: 992px) {
  #intro {
    /* margin-top: -58.59px; */
    margin-top: 0px;
  }
}

.mask-tn-1 {
  background: linear-gradient(
            45deg,
            rgba(29, 236, 197, 0.7),
            rgba(91, 14, 214, 0.7) 100%
          );
}
</style>